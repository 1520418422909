import { useRef, useState, useEffect } from "react";
import List from "./List/List";
import Map from "./Map/Map";
import Search from "./Search/Search";
import axios from "axios";
import double_arrow_bold from "../../images/double_arrow_bold.svg";

// Updated SearchWithList component to use ref
const SearchWithList = ({ children, listRef }) => (
  <div className="SearchWithList" ref={listRef}>
    {children}
  </div>
);

export default function Pszok(props) {
  const [focusPszok, setFocusPszok] = useState("");
  const [focusElektro, setFocusElektro] = useState("");
  const [currentPlace, setCurrentPlace] = useState("");
  const [pszokType, setpszokType] = useState("PSZOK");
  const [elektroType, setelektroType] = useState("Elektroodpady");
  const [serachTerm, setSearchTerm] = useState("");
  const [pszokData, setpszokData] = useState(props.pszokData);
  const [pszokDataMap, setpszokDataMap] = useState(props.pszokData);
  const [scopeData, setScopeData] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [size, setSize] = useState(20);
  const [scroll, setScroll] = useState();
  const [hasMoreData, setHasMoreData] = useState(true);
  const listRef = useRef(null);
  const mapRef = useRef();
  const [loading, setLoading] = useState(false);

  const defaultProps = {
    center: {
      lat: 52.3957687668395,
      lng: 19.22097674919009,
    },
    zoom: 6.2,
  };

  // Fetch PSZOK data
  const pszok = () => {
    setpszokData(props.pszokData);
    setpszokDataMap(props.pszokData);
  };

  const lazyLoading = () => {
    const scrollableDiv = listRef.current;
    scrollableDiv.addEventListener("scroll", handleScroll);
  };

  const handleScroll = () => {
    const scrollableDiv = listRef.current;
    const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;

    const scrollPercentage = Math.round(
      (100 * scrollTop) / (scrollHeight * (1 - clientHeight / scrollHeight))
    );
    setScroll(scrollPercentage);

    // Check if scroll is at the bottom and if there is more data to load
    if (scrollPercentage === 100 && hasMoreData) {
      setLoading(true);
      addSize(size, 20);
      setLoading(false);
    }
  };

  // Function to add more items
  function addSize(currentSize, increment) {
    const newSize = currentSize + increment;
    if (newSize >= pszokData.length) {
      // If the new size exceeds or matches total data, stop further loading
      setSize(pszokData.length);
      setHasMoreData(false); // Disable further loading
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll); // Remove scroll listener
      }
    } else {
      setSize(newSize);
    }
  }

  // Scroll handling effect
  useEffect(() => {
    if (listRef.current && hasMoreData) {
      lazyLoading();
    }

    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scroll, size, hasMoreData]);

  async function searchByCityName(term) {
    if (term === "") {
      defaultOptions();
      return;
    }
    setSearchTerm(term);
    getCoords(term);
    ToggleListWithBtn();
    setShowBtn(false);
  }

  async function getCoords(city) {
    try {
      const geocodeURl = "https://maps.googleapis.com/maps/api/geocode/json?";
      const getData = await axios.get(geocodeURl, {
        params: {
          address: city,
          key: props.api_key,
        },
      });
      const moveTo = await getData.data.results[0].geometry.location;
      await mapRef.current.panTo(moveTo);
      await mapRef.current.setZoom(12);
    } catch (error) {
      alert("Zła nazwa miejscowości");
      console.error(error);
    }
  }

  async function defaultOptions() {
    pszok();
    ToggleListWithBtn();
    setSearchTerm("");
    setScopeData("");
    setShowBtn(true);
    await mapRef.current.panTo(defaultProps.center);
    await mapRef.current.setZoom(defaultProps.zoom);
  }

  function ToggleListWithBtn() {
    const list = listRef.current;
    const btn = document.querySelector(".toggleBtn");

    list.classList.toggle("hideList");
    btn.classList.toggle("hideBtn");
    setIsToggle(!isToggle);
  }
  function resetMarkers() {
    setFocusElektro("");
    setFocusPszok("");
  }

  async function showInfo(id) {
    const findList = () => pszokData.find((el) => el.ID === id);

    if (listRef.current.classList.contains("hideList")) {
      ToggleListWithBtn();
    }
    mapRef.current.setZoom(18);
    setScopeData([findList()]);
    mapRef.current.panTo({
      lat: parseFloat(findList().lat),
      lng: parseFloat(findList().lng),
    });
  }

  const filteredData = pszokDataMap
    ?.filter(
      (list) =>
        list.miejscowosc?.toLowerCase().includes(serachTerm.toLowerCase()) &&
        (list.typ?.includes(pszokType) || list.typ?.includes(elektroType))
    )
    .slice(0, size);

  return (
    <div className="PszokApp">
      <button
        className="toggleBtn"
        onClick={() => {
          ToggleListWithBtn();
        }}
      >
        {isToggle ? (
          <div>
            <p>Pokaż</p>{" "}
            <img className="hidenBtn" src={double_arrow_bold} alt="arrow" />
          </div>
        ) : (
          <div>
            <p>Schowaj</p>{" "}
            <img className="openBtn" src={double_arrow_bold} alt="arrow" />
          </div>
        )}
      </button>

      <SearchWithList listRef={listRef}>
        <Search
          searchTerm={(term) => searchByCityName(term)}
          pszokType={(type) =>
            type ? setpszokType("PSZOK") : setpszokType("-PSZOK")
          }
          elektroType={(type) =>
            type
              ? setelektroType("Elektroodpady")
              : setelektroType("-Elektroodpady")
          }
          hide={showBtn}
          currentPlace={currentPlace}
          term={serachTerm}
          resetTerm={() => defaultOptions()}
          return={async (term) => {
            if ((mapRef.current.zoom <= 12 && term !== "") || term === "") {
              await defaultOptions();
            }

            if (mapRef.current.zoom > 12 && serachTerm === term) {
              setScopeData("");
              await getCoords(term);
              resetMarkers();
            }
          }}
        />
        <List
          hideList={() => {
            setShowBtn(false);
            ToggleListWithBtn();
          }}
          cityFilter={serachTerm}
          TypesFilter={[pszokType, elektroType]}
          pszokData={filteredData}
          scopeData={scopeData}
          CustomPosition={(x, y) => mapRef.current.panTo({ lat: x, lng: y })}
          loading={loading}
        />
      </SearchWithList>

      <Map
        setFocusPszok={(pszokID) => setFocusPszok(pszokID)}
        setFocusElektro={(elektroID) => setFocusElektro(elektroID)}
        focusPszok={focusPszok}
        focusElektro={focusElektro}
        api_={props.api_key}
        pszokData={pszokDataMap}
        mapRef={mapRef}
        returnerBtn={(btn) => setShowBtn(btn)}
        markerFilter={[pszokType, elektroType]}
        getId={(id) => showInfo(id)}
        returnName={(term) => {
          setCurrentPlace(term);
        }}
        serachTerm={serachTerm}
      />
    </div>
  );
}
