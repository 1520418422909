export default function List(props) {

  const PszokList = (element) => {
    return (
      <li className="pszok" key={element.ID}>
        <p className="bold">{element.nazwa}</p>
        <p>
          {element.ulica}, {element.miejscowosc}, {element.wojewodztwo}
        </p>
        <p>{element.wskazowki_dojazdu}</p>
        <p >{element.operator}</p>
        {element.telefon && <p>telefon:{element.telefon}</p>}
        {element.www && (
          <a className="margin" href={element.www} rel="noreferrer" target="_blank">
            www
          </a>
        )}
        {element.wrzucamy && (<><p className="margin">wrzucamy:</p><p>{element.wrzucamy}</p></>)}
        {element.nie_wrzucamy && (<><p className="margin">nie wrzucamy:</p><p>{element.nie_wrzucamy}</p></>)}
        <p className="margin">godziny otwarcia</p>
        <p>{element.godziny_otwarcia_poniedzialek}</p>
        <p>{element.wtorek}</p>
        <p>{element.sroda}</p>
        <p>{element.czwartek}</p>
        <p>{element.piatek}</p>
        <p>{element.sobota}</p>
        <p>{element.niedziela}</p>
        <button
          onClick={async () => {
            await props.hideList();
            await props.CustomPosition(element.lat, element.lng);
          }}
        >
          Pokaż na mapie
        </button>
      </li>
    );
  };



  return (
    <>
      <div
        className="list"
      >

        <ul>
          {props.pszokData.map((pszok) => PszokList(pszok))}
        </ul>
      </div>
      {props.loading && <p style={{ textAlign: "center", padding: "12xp 12px", color: "#fff" }}>Ładowanie...</p>}
    </>
  );
}
