import "./App.css";
import Pszok from "./components/Pszok/Pszok";
import MyHeader from "./components/Header/Header";
import MyFooter from "./components/Footer/Footer";
import { pszok_aktualne } from "./components/pszok_aktualne_min.json";
require("dotenv").config({ path: "../" });
function App() {
  const api_key = process.env.REACT_APP_API_KEY;
  return (
    <div className="App">
      <MyHeader />
      <Pszok pszokData={pszok_aktualne} api_key={api_key} />
      <MyFooter />
    </div>
  );
}

export default App;
