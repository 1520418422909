import fb from '../../images/facebook.png'
import mailbox from '../../images/mailbox.png'
import oiler from '../../images/oiler.png'
import quellio from '../../images/quellio.png'
import studio102 from '../../images/studio102.png'

function MyFooter() {
    return(
        <footer>
            <div className='reportPoint'>
                <a href="mailto:info@zglowa.com?subject=Zgłoś punkt - pszok" className='flex'>
                    <img src={mailbox} alt='koperta'/>
                    <p className='space'>Zgłoś punkt</p>
                </a>
            </div>

            <div className='organizers'>
                <div className='organizerContainer'>
                    <img src={oiler} alt='olier'/>
                    <p>Mecenas projektu</p>
                </div>
                <div className='organizerContainer'>
                    <img src={studio102} alt='studio102/'/>
                    <p>organizator</p>
                </div>
            </div> 

            <div className='findUs'>
                <a href="https://www.facebook.com/oilersa/" target="_blank" rel='noreferrer'>
                    <img src={fb} alt='fb'/>
                </a>
            </div>

            <div className='author'>
                <p className='space'>Realizacja</p>
                <a href="https://www.quellio.com/" target="_blank" rel='noreferrer'>
                    <img src={quellio} alt='quellio/'/>
                </a>
            </div>
        </footer>
    )
}

export default MyFooter;