import GoogleMapReact from "google-map-react";
import {useState} from 'react';
import useSupercluster from 'use-supercluster';
import pszok_img from '../../../images/pinezka_pszok.svg'
import elektro_img from '../../../images/pinezka_elektro.svg'
import pszok_selected from '../../../images/pszok_selected.svg'
import elektro_selected from '../../../images/elektro_selected.svg'
import mapStyle from './mapStyles.json'
const Marker = ({children}) => <div>{children}</div>;

const defaultProps = {
    center: {
      lat: 52.3957687668395,
      lng: 19.22097674919009
    },
    zoom: 6.2
};

export default function Map(props) {

    const ActivePszok = id => {
        if(id === props.focusPszok) {
            return <img src={pszok_selected} alt="pszok_selected"/>
        }
        else {
            return <img src={pszok_img} alt="pszok"/>
        }
    };
    const ActiveElektro = id => {
        if(id === props.focusElektro) {
            return <img src={elektro_selected} alt="elektro_selected"/>
        }
        else {
            return <img src={elektro_img} alt="elektro"/>
        }
    };

    const [zoom,setZoom] = useState(10);
    const [bounds,setBounds] = useState(null)
    //map props
    const points = props.pszokData.filter(marker => (marker.typ?.includes(props.markerFilter[0]) || (marker.typ?.includes(props.markerFilter[1])))).map(crime => ({
        type: "Feature",
        properties: {
            clusters: false,
            crimeId: crime.ID,
            category: crime.typ,
        },
        geometry: {
            type:"Point",
            coordinates: [
                parseFloat(crime.lng),
                parseFloat(crime.lat)
            ]}
    }))
     //get clusters
      const { clusters } = useSupercluster({
        points,
        bounds,
        zoom,
        options: {radius:120, maxZoom:16}
    })

    return(
        <div id="mainMap" className='map'>
            <GoogleMapReact
                    bootstrapURLKeys={{key:props.api_}}
                    defaultZoom={defaultProps.zoom}
                    defaultCenter={defaultProps.center}
                    yesIWantToUseGoogleMapApiInternals
                    options={{styles:mapStyle}}
                    onGoogleApiLoaded={({map}) => {
                       props.mapRef.current = map;
                    }}
                    onChange={({ zoom, bounds }) => {
                        setZoom(zoom)
                        setBounds([
                            bounds.nw.lng,
                            bounds.se.lat,
                            bounds.se.lng,
                            bounds.nw.lat
                        ])
                    }}
                >
                    {
                        clusters.map((cluster,index) => {
                            const [longitude,latitude] = cluster.geometry.coordinates;
                            const {
                                cluster: isCluster,
                                point_count: pointCount
                            } = cluster.properties;
 
                            if(isCluster) {
                                return(
                                    <Marker
                                    key={clusters[index].id}
                                    lat={latitude}
                                    lng={longitude}
                                >
                                    <div 

                                        className='cluster'
                                        style={{
                                            width: `${12 + (pointCount / points.length) * 50}px`,
                                            height: `${12 + (pointCount / points.length) * 50}px`
                                        }}
                                        onClick={
                                            ()=>{
                                                props.mapRef.current.panTo({'lat':latitude,'lng':longitude})
                                                props.mapRef.current.setZoom(zoom + 2)
                                            }
                                        }
                                    >
                                        {pointCount}
                                    </div>
                                </Marker>
                                )
                            }
 
                            return(
                                <Marker
                                    key={cluster.properties.crimeId}
                                    lat={latitude}
                                    lng={longitude}
                                    onClick={()=>{
                                        cluster.properties.infoBox = true
                                    }}
                                >
                                    <button 
                                        className={`Marker`}
                                        onClick={()=>{
                                            if(props.serachTerm !== "") {
                                                // props.returnName("Polska")
                                                props.returnName(props.serachTerm)

                                            }
                                            else {
                                                props.returnName("Polska")
                                            }
                                            props.getId(cluster.properties.crimeId)
                                            props.setFocusPszok(cluster.properties.crimeId)
                                            props.setFocusElektro(cluster.properties.crimeId)
                                            props.returnerBtn(false)
                                        }}
                                    >
                                        {
                                            cluster.properties.category.includes("PSZOK") ? ActivePszok(cluster.properties.crimeId) : ActiveElektro(cluster.properties.crimeId)  
                                        }      
                                    </button>
                                
                                </Marker>
                            )
                        })
                    }
 
                </GoogleMapReact>
            </div>
    )
}