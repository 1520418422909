import logo from '../../images/logo_z_glowa.svg'
import searchZoom from '../../images/lupka.svg'

function MyHeader() {

    return(
        <header>
           <div className='topLevelHeader'>
                <div className='logo'>
                   <a href='https://www.zglowa.com' rel='noreferrer'>
                    <img src={logo} alt='ZGŁOWĄ'/>
                   </a>
                </div>
                <div className='logo-text'>
                    <span className='extra-style-logo'>/</span>Pozbądź się wyszystkiego, co nie jest Ci potrzebne
                </div>
           </div>

            <div className='navigation'>
                <div className='buttonContainer'>
                    <div className='navButton'><img src={searchZoom} alt='search'/><span>oddaj odpady</span></div>
                </div>
                <nav>
                    <li><a href='https://www.zglowa.com/idea.html' rel='noreferrer'>idea</a></li>
                    <li><a href='https://www.zglowa.com/partnerzy.html' rel='noreferrer'>partnerzy</a></li>
                    <li><a href='https://www.zglowa.com/nagrody.html' rel='noreferrer'>nagrody</a></li>
                    {/* <li><a href='https://www.zglowa.com/press.html' rel='noreferrer'>press</a></li> */}
                    <li><a href='https://www.zglowa.com/faq.html' rel='noreferrer'>faq</a></li>
                    <li><a href='https://www.zglowa.com/kontakt.html' rel='noreferrer'>kontakt</a></li>
                </nav>
            </div>
            <div 
                id="hamburger"
                onClick={(e)=>{
                    const nav = document.querySelector(".navigation");
                    e.currentTarget.classList.toggle('open')
                    nav?.classList?.toggle('showNav')
                }}
            >
                <div></div>
            </div>
        </header>
    )
}

export default MyHeader;